.welcome img {
    width: 100%;
}

.welcome {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3rem;
}

.welcome .post {
    margin: 5rem 3rem; 
    width: 100%;
    max-width: 80rem;
    padding: 2rem;
    padding-right: 0rem;
    background-color: #fff;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    border-radius: .8rem;
}

.welcome .post .post-img {
    min-width: 15rem;
    max-width: 15rem;
    height: auto;
    transform: translateX(-6rem);
    position: relative;
}

.welcome .post .post-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: .8rem;
}

.welcome .post .post-img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: linear-gradient(to right, rgba(79, 172, 254, .8),rgba(0, 242, 254, .8)); */
    box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, .05);
}

.welcome .post .post-info {
    transform: translateX(-4rem);
}

@media screen and (max-width: 1068px) {
    .welcome .post {
        max-width: 80rem;
    }

    .welcome .post .post-img {
        min-width: 13rem;
        max-width: 13rem;      
        transform: translateX(-4rem);
    }
    .welcome .post .post-info {
        transform: translateX(-2rem);
    }
}

@media  screen and (max-width: 868px) {
    .welcome .post {
        max-width: 70rem;
    }

    .welcome .post .post-img {
        transform: translateX(-6rem);
    }

    .welcome .post .post-info {
        transform: translateX(-3rem);
    }
}

@media  screen and (max-width: 768px) {
    .welcome {
        padding-left: 0;
    }

    .welcome .post {
        margin: 1rem;       
        padding: 3rem;
        flex-direction: column;
    }

    .welcome .post .post-img {
        max-width: 100%;
        height: 20rem;
        transform: translateY(0.1rem);
    }

    .welcome .post .post-info {
        transform: translateX(0);
    }

    .welcome .post .post-info .title_h1 {       
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
    .welcome .post {      
        padding: 1.5rem;
        flex-direction: column;
    }
}