:root{
    --primary:#9CA30C;
    --primary-light:#6b7000;
    /* --primary-light:#37a30c; */
    --secondary:#612a65;
    --secondary-light:#d800e7;

    --gradient:linear-gradient(90deg, var(--primary), var(--primary-light));
    --gradient-secondary:linear-gradient(90deg, var(--secondary), var(--secondary-light));

    --font-size-title-h1: 2.1rem;
    --font-size-title-h2: 1.8rem;
    --font-size-title-h3: 1.6rem;
    --font-size-title-h4: 1.4rem;
    --font-size-title-h5: 1.4rem;
    --font-size-text: 1.05rem;
    
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: 'Roboto', sans-serif;
    font-size: var(--font--text);
}

/* background */
body {
    /* background: url('/src/images/bg.jpg') 0 0 repeat scroll #f4f4f9; */
    background: url('/src/images/bg2.png') 0 0 repeat scroll #f4f4f9;
}

/* Title */
.title_h1{
    display: block;
    text-align: center;
    background:var(--gradient);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: var(--font-size-title-h1);
    text-transform: uppercase;
    font-weight: bolder;
}

.title_h2{
    display: block;
    text-align: center;
    background:var(--gradient);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: var(--font-size-title-h2);
    text-transform: uppercase;
    font-weight: bolder;
}


.title_h3{
    display: block;
    text-align: center;
    background:var(--gradient);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: var(--font-size-title-h3);
    text-transform: uppercase;
    font-weight: bolder;
}
.title_h4{
    display: block;
    /* text-align: center; */
    background:var(--gradient);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: var(--font-size-title-h4);
    text-transform: uppercase;
    font-weight: bolder;
}

.title_h5 {
    color: #333;
    font-size: var(--font-size-title-h5);
    /* text-align: justify; */
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 20px;
}

p {
    text-align: justify;
    font-size: var(--font-size-text);
    margin-bottom: .8rem;
    color: rgba(0, 0, 0, .7);
}

a {
    font-size: var(--font-size-text);
    text-decoration: none !important;
}

li {
    font-size: var(--font-size-text);
}
