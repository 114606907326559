.carousel-caption {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

.carousel-item img{
    object-fit: cover;
    width: 100%;
    height: 90vh;
    max-height: 1200px !important;
}

@media only screen and (max-width: 479px) {
    .carousel-item img{
        height: 50vh;
    }
    .card-text{
        font-size: .6rem !important;
    }   
}

