.container-service{
    width: 90%;
    max-width: 1200px;
    height: 100%;
    margin: auto;
}

.serviceGrid { 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin-bottom: 50px;
}

.serviceGrid > article {
    object-fit: cover;
    background: #ffffff;
    border: none;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    text-align: center;
    transition: transform .3s;
}

.serviceGrid > article:hover {
    transform: translateY(2px);
    box-shadow: 2px 2px 26px 0px rgba(0, 0, 0, .3);
}

.serviceGrid > article img {
    width: 100%;
    height: auto;
    margin: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.text {
    padding: 0 20px 20px;
    text-align: justify;
}

.text .card-title {
    text-transform: uppercase;
    text-align: center ;
    font-size: var(--font-size-title-h4);
}

@media screen and (max-width: 1068px) {
    .serviceGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 650px) {
    .serviceGrid {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }

    .serviceGrid > article {
        text-align: center;
        padding: 0rem;
        min-width: 10rem;
        max-width: 22rem;
    }
}

@media (max-width: 500px) {
    .container-service{
        width: 100%;
    }
}