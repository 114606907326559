.container-business {
    width: 90%;
    max-width: 1200px;  
    min-height: 100%;
    margin: auto;   
}

.container-business .containerCard {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.container-business .containerCard .theCard {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 15px;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.container-business .containerCard .theCard:hover {
    border: 1px solid var(--primary);
}

.container-business .containerCard .theCard .theBack {
    margin: 10px;
}

@media  screen and (max-width: 768px) {

    .container-business .containerCard .theCard {
        grid-template-columns: 1fr;
    }

    .container-business .containerCard .theCard .theFront{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media  screen and (max-width: 480px) {
    .container-business {
        width: 100%;
    }

    .container-business .containerCard .theCard .theFront img{
        width: 90%;
    }
}