.navbar {
    padding: .1rem !important;
}

.sticky-top {
    background-color: #ffffff;
}

header nav a {
    font-size: 1.1rem;
    color: #000000 !important;
}

.nav-link:hover ,
a.active {
    color: #9CA30C !important;
    border-bottom: 2px solid #9CA30C;
}

.navbar-toggler-icon {
    color: #9CA30C !important;
}

.basic-nav-dropdown {
    text-transform: capitalize;
}

.dropdown-item:hover {
    color: #9CA30C !important;
}


.dropdown-item:active {
    background-color: #ffffff !important;
}

.dropdown-item.active {
    background-color: #ffffff !important;
}

@media screen and (min-width: 1440px){
	.navbar-brand img{
		width: 300px !important;
        height: auto;
        margin-left: 50px !important;
	}
}

@media screen and (max-width: 985px){
	header nav a {
		text-align: center;
	}
}

@media screen and (max-width: 350px){
	.navbar-brand img{
		width: 200px !important;
	}
}

@media screen and (max-width: 320px){
	.navbar-brand img{
		width: 180px !important;
	}
}