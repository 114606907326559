.box-404 {
    width: 100%;
    max-width: 1200px;
    max-height: 100%;
    margin: auto;
    background-color: white;
}

.box-404 .content-404 {
    display: grid;
    grid-template-columns: 1fr;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-404 .content-404 .text-404 strong{ 
    margin: 20px;
}

.box-404 .content-404 .image-404 img{
    width: 100%;
    max-width: 400px;
    height: auto;
}

.box-404 .content-404 .btn-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 100px;
}