.btn {
    display: block !important;
    padding: 1.5rem 3rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #fff !important;
    background-image: linear-gradient(to right, var(--secondary) 0%, var(--secondary-light) 100%);
    border-color: transparent !important;
    border-radius: .8rem;
    text-decoration: none;
}

.btn:hover {
    background-image: linear-gradient(to right, var(--secondary-light) 0%, var(--secondary) 100%);
}

/* Horizontal Card */
.card-text {
    text-align: justify;
}

/* h2 {
    color: var(--primary);
} */

/* Bg Double Card */
.bgPurple {
    background-color: var(--secondary);
    border-radius: 10px;
    color: white;
}

/* box-shadow  */
.card { 
    border-radius: 10px;
    background-color: #ffffff !important;;
    color: #000000;
    box-shadow: 10px 10px 6px -6px rgba(0, 0, 0, 0.295);
}

.clearfix {
    text-align: justify;
}

.about {
    border-radius: 10px;
}