.container-clientAccount {
    width: 90%;
    max-width: 1200px;  
    min-height: 100%;
    margin: auto;
}

.container-clientAccount .login p{
    text-align: center !important;
}

.container-clientAccount .container-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.container-clientAccount .container-cards .cards {
    min-height: 180px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container-clientAccount .container-cards .cards:hover {
    /* background-color: var(--primary); */
    border: 1px solid var(--primary);
}

.container-clientAccount .container-cards .cards img {
    width: 90%;
    height: auto;
    margin: auto;
}

.container-clientAccount .container-cards .cards .box-title {
    color: black;
    align-items: baseline;
}

/* Small Devices, Tablets */
@media only screen and (min-width : 1024px) {
    .container-clientAccount {  
        min-height: 60vh;
    }
}


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

    .container-clientAccount .container-cards {
        grid-template-columns: 1fr 1fr;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

    .container-clientAccount .container-cards {
        grid-template-columns: 1fr;
    }
}