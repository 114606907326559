/* Footer */
footer {
    width: 100%;
    background-color:var(--secondary);
    color: #eee;
    padding: 2rem 1rem 0 1rem;
    
    /* display: grid;
    place-content: center;
     */
}

footer .container{
    max-width: 1240px;
    margin: auto;
    place-content: center;
    
}

footer .whiteLogo {
    display:flex;
    height: auto;
    justify-content: center;
    align-items: center;
}

.bottom {
    text-align: center;
    padding-top: 1rem;
    color: #fff;
}

.line {
    margin-bottom: 1rem;
}

footer .disclosure {
    margin-top: 20px;
}

footer .disclosureText {
    font-size: 12px;
    text-align: justify;
    color: #fff;
}

footer .disclosureText a{
    font-weight: bold;
    color: white;
    text-decoration-line: none;
    font-size: 14px !important;
}

footer .disclosureText a:hover{
    color: var(--primary);
}

footer li.contactText  {
    padding: 0;
    margin: 0;
    line-height:0    
}

.container .content a {
    display: block;
    font-size: 1rem;
    color: #fff !important;
    text-decoration: none;
} 

.container .content a i{
    padding-top: 10px;
    color: #fff;
}

.container .content a:hover {
    color: var(--primary) !important;
}


.fa-phone-alt:before {
    content: "\f2a0";
}

.fa-at:before {
    content: "\f1fa";
}
.fa-map-marker-alt{
    content: "\f3c5";
}
.fas {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i {

    color: #ffff;
    font-size: 30px;
    padding-left: 2px;
    width: 40px;
    height: 40px;
    /* text-align: center; */
    /* position: relative; */
    left: 5px;

}

/* Medium Devices, Desktops */
@media screen and (max-width: 992px){
    footer .container{
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;

    }

    footer .contactText{
        width: 100%;
        height: auto;
    }
  
    footer .whiteLogo {
        margin-top: 20px;
    }
}

/* Small Devices, Tablets */
@media screen and (max-width: 600px){
    footer .container{
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 30px;

    }

    footer .servicesList{
        width: 100%;
        margin-top: 20px;
    }

    footer .contactTextCol{
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    footer li a:hover {
        padding-bottom: 3px;
        border-bottom: 3px solid #9CA30C;
    }

    footer .disclosureText {
        word-spacing: 0;
    }  
}

@media screen and (max-width: 380px){
    .container .content .email{
        font-size: 0.8rem !important;
    }

    footer .whiteLogo img{
        width: 90%;
    }
}

@media screen and (max-width: 1000px){

    footer .whiteLogo {
        width: 100%;
    }
}

/* End Footer */