.box-map {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
}

.box-map .box {
    width: 60rem;
    margin: 2rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0 5px 15px #0009;
}

.box-map .box .imageMap {
    margin: .5rem;
    border-radius: 10px;
    display: block;
    height: auto;
    width: 50%;
} 

.box-map .box .imageMap img {
    width: 100%;
    border-radius: 10px;
}

.box-map .box .content {
    text-align: center;
} 

.box-map .box .content a {
    display: block;
    font-size: 1rem;
    color: black !important;
    text-decoration: none;
} 

.box-map .box .content a i{
    padding-top: 10px;
    color: black;
    align-items: center;
}

.box-map .box .content a:hover {
    color: var(--primary) !important;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1060px) {
    .box-map .box {
        width: 55rem;
        margin: 1rem;
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
    .box-map .box {
        width: 45rem;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .box-map .box {
        flex-flow: column;
        padding-bottom: 2rem;
        margin: 1rem;
        width: 30rem;
    }

    .box-map .box .imageMap {
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        width: 100%;
        height: auto;
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
    .box-map .box .content {
        padding: 10px;
    } 
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 380px) {   
    .box-map .box .content a{
        font-size: .83rem !important;
    }   
}