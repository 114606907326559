/* filter search */

.box-search {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.box-search .search {
    display: flex;
    width: 1250px;
    height: auto;
    justify-content: end;
}

.box-search .search .form-control {
    width: 50% !important;
    float: right !important;
}

.box-search .search .form-control:focus {
    box-shadow: 0 0 5px var(--primary-light) !important;
    border: 2px solid var(--primary) !important;
}


/* Service */
.containerServices {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; 
}

.card-container {
    display: flex;
    width: 1250px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    background: #fff;
    border-radius: .8rem;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, .2);
}

.header {
    border-right: 1px solid #ddd;
}

.description {
    background: #fbfbfb;
    margin: 20px 30px;
    /* font-size: 1.4rem; */
}

.header, .description {
    padding: 20px;
    
}

.header a {
    position: relative;
    display: block;
    text-decoration: none;
    text-align: center;
    
}

.header img {
    box-shadow: 0 0 0 8px rgba(247, 183, 247, .1);
    margin: 70px 5px;
    height: 300px;
    width: 300px;
}

.header a:hover img {
    box-shadow: 0 0 0 12px rgba(156,163,12, 0.2);
}

.description .title_h2 {
    padding: 10px 10px 30px;
    text-decoration: underline;
}

.description .contact {
    margin-top: 25px;
}

/* Small Devices, Tablets */
@media (max-width:767px){
    .card-container{
        display: block;
        width: 450px;
        
    }

    .header{
        padding: 20px 20px 0 20px;
        border: none;
    }
    .header img {
        margin: 0;
    }

    .description {
        padding: 0 20px 20px 20px;
        background: transparent;
        margin: 10px;
    }

    .description h2 {
        margin: 0;
    }

    /* search */
    .box-search .search {
        width: 450px;
        justify-content: center;
    }

    .box-search .search .form-control {
        width: 100% !important;
    }
}

/* End Service */